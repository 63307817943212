import React, { useState } from "react";
import AccordionLayout from "./accordionLayout";
// import StarGreen from "../../../images/icons/StarGreen.svg";
// import StarTwoYellow from "../../../images/icons/StarTwoYellow.svg";
// import StarThreeOrange from "../../../images/icons/StarThreeOrange.svg";
// import PetPaw from "../../../images/icons/PetPaw.svg";

const CARE_DIFFICULT_CONSTANT = [
  { label: "Pet Friendly", value: "TRUE" },
  { label: "Low light", value: "Toxic" },
];

const Accordion = ({
  data,
  filterHandler,
  isFilter,
  levelHandler,
  isToxic,
}) => {
  const [isCard, setIsCard] = useState(true);
  const [isType, setIsType] = useState(true);
  let plants = data?.edges;
  let plantTypes = [];

  plants?.filter((ele) => {
    let isDuplicate = plantTypes?.includes(ele?.node?.plant_types);
    if (!isDuplicate) {
      plantTypes.push(ele?.node?.plant_types);
      return true;
    }
    return false;
  });

  return (
    <div className="w-full flex flex-col justify-between items-center md:w-[205px]">
      <AccordionLayout
        title="Type"
        state={isType}
        onClick={() => setIsType(!isType)}
      >
        {isType && (
          <ul
            className="p-3 space-y-3 text-sm"
            aria-labelledby="dropdownCheckboxButton"
          >
            {plantTypes?.map((item, index) => (
              <li key={index} class="flex items-center justify-between ">
                <label
                  for={index}
                  class=" text-base font-medium text-avenir400 cursor-pointer"
                >
                  {item}
                </label>
                <input
                  id={index}
                  type="checkbox"
                  checked={isFilter.includes(item)}
                  value={item}
                  className="w-5 h-5 text-green bg-gray-100 rounded-xl border-gray-300 focus:ring-0 fous-outline-none cursor-pointer"
                  onClick={filterHandler}
                />
              </li>
            ))}
          </ul>
        )}
      </AccordionLayout>

      <AccordionLayout
        title="Care difficulty"
        state={isCard}
        onClick={() => setIsCard(!isCard)}
      >
        {isCard && (
          <ul
            className="p-3 space-y-3 text-sm"
            aria-labelledby="dropdownCheckboxButton"
          >
            {CARE_DIFFICULT_CONSTANT.map((item, index) => {
              return (
                <li class="flex items-center justify-between" key={index}>
                  <label
                    for={index}
                    class="flex text-base font-medium text-avenir400 cursor-pointer"
                  >
                    {item.label}
                  </label>
                  <input
                    id={index}
                    value={item.value}
                    type="checkbox"
                    checked={item.value === isToxic}
                    onClick={levelHandler}
                    className=" w-5 h-5 text-green bg-gray-100 rounded-xl border-gray-300 focus:ring-0 focus-outline-none cursor-pointer"
                  />
                </li>
              );
            })}
          </ul>
        )}
      </AccordionLayout>
    </div>
  );
};

export default Accordion;
