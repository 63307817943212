import React from "react";
import Arrow from "../../Arrow";

const AccordionLayout = ({ title, children, onClick, state }) => {
  return (
    <>
      <div
        onClick={onClick}
        className="flex w-full justify-between px-2 cursor-pointer"
      >
        <div className="flex flex-col justify-between ">
          <div className="text-lg font-bold text-avenir900 ">{title}</div>
        </div>
        <div className="flex text-black">
          {state ? (
            <Arrow
              color="gray"
              className="transform duration-500 ease rotate-180"
            />
          ) : (
            <Arrow color="gray" className="transform duration-500 ease" />
          )}
        </div>
      </div>
      <div className="flex flex-col w-full justify-between rounded-2xl overflow-auto ">
        {children}
      </div>
    </>
  );
};

export default AccordionLayout;
