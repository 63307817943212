import React from "react";
import Modal from "react-modal";
// import { Link, navigate } from "gatsby";
// import Filter from '../Filter';
import cross from "../../images/icons/cross.svg";
import Accordion from "../Filter/demo/accordion";
import { LazyLoadImage } from "react-lazy-load-image-component";

function ModalInFunctionalComponent({
  data,
  filterHandler,
  isFilter,
  openModal,
  setOpenModal,
  levelHandler,
  isToxic,
}) {
  return (
    <Modal
      className="relative w-full mt-16 h-full bg-white px-4 overflow-x-hidden overflow-y-auto"
      isOpen={openModal}
    >
      <div className="flex flex-row justify-between font-avenir800 bg-white  focus:outline-none  font-2xl rounded-lg text-xl px-2 py-2.5 ">
        <p>Filters</p>
        <LazyLoadImage onClick={() => setOpenModal(false)} className="h-5" src={cross} />
      </div>
      <Accordion
        data={data}
        filterHandler={filterHandler}
        isFilter={isFilter}
        levelHandler={levelHandler}
        isToxic={isToxic}
      />
    </Modal>
  );
}
export default ModalInFunctionalComponent;
