import React, { useEffect, useState } from "react";
import { Link, graphql, navigate } from "gatsby";
import SearchBar from "../search";
import { useFlexSearch } from "react-use-flexsearch";
import { LazyLoadImage } from "react-lazy-load-image-component";
import BlogFooter from "../Layout/BlogFooter";
import Layout from "../Layout";
// import Filter from "../Filter";
import DownloadAppBar from "../Layout/DownloadAppBar";
import ModalInFunctionalComponent from "../Modal";
import FullScreenFilter from "./fullScreenFilter";
import LeftArrow from "../../assets/svg/LeftArrow";
import RightArrow from "../../assets/svg/RightArrow";
import EllipsePagination from "../ellipsePagination";
import PlantPlaceholder from "../../images/PlantPlaceholder.png";
import { SHOW_ANNOUCEMENT_BANNER } from "../../utils/constant";
export const unFlattenResults = (results) => {
  return results.map((post) => {
    const node = { node: post };
    return node;
  });
};

const Plant = ({
  data: {
    allPlants: { edges },
    data,
    localSearchPages,
  },
  pageContext,
}) => {
  const index = localSearchPages.index;
  const store = localSearchPages.store;
  const [searchQuery, setSearchQuery] = useState("");
  const results = useFlexSearch(searchQuery, index, store);
  const [plants, setPlants] = useState(edges);
  const previousIndex = pageContext.currentPage - 1;
  const nextIndex = pageContext.currentPage + 1;
  const [isFilter, setIsFilter] = useState([]);
  const [isToxic, setIsToxic] = useState("");
  const [hasFilter, setHasFilter] = useState(false);
  // const [totalPage, setTotalPgae] = useState(0);

  const previousPageUrl =
    previousIndex === 1
      ? "/explore-plants"
      : `/explore-plants/${previousIndex}`;
  const nextPageUrl = `/explore-plants/${nextIndex}`;
  const [openModal, setOpenModal] = useState(false);

  // useEffect(() => {
  //   if (isFilter.length) {
  //     setTotalPgae(Math.ceil(plants?.length / 52));
  //   } else {
  //     setTotalPgae(Math.ceil(data?.edges?.length / 52));
  //   }
  // }, [nextIndex, previousIndex, plants, isFilter]);

  const filterHandler = (e) => {
    let data = [];
    if (isFilter.includes(e.target.value)) {
      data = isFilter.filter((items) => items !== e.target.value);
      setIsFilter(data);
    } else {
      setIsFilter((pre) => [...pre, e.target.value]);
    }
  };

  const levelHandler = (e) => {
    if (e.target.value === isToxic) {
      setIsToxic("");
    } else {
      setIsToxic(e.target.value);
    }
  };

  const searchHandler = (e) => {
    setSearchQuery(e.target.value);
  };

  useEffect(() => {
    if (isFilter?.length === 0) {
      if (searchQuery !== "") {
      } else {
        setPlants(edges);
      }
    } else {
      setSearchQuery("");
      setPlants(
        data.edges?.filter((items) =>
          isFilter?.includes(items?.node?.plant_types)
        )
      );
    }
  }, [isFilter]);

  useEffect(() => {
    if (searchQuery !== "") {
      setPlants(unFlattenResults(results));
      setIsFilter([]);
    } else {
      if (isFilter?.length > 0) {
      } else {
        setPlants(edges);
      }
    }
  }, [searchQuery]);

  const onNumberClick = (number) => {
    if (number === 1) {
      navigate(`/explore-plants/`);
    } else {
      navigate(`/explore-plants/${number}/`);
    }
  };

  return (
    <Layout pageTitle="Home Page" title="Flora | Explore Plants">
      <div className="flex flex-col items-center">
        <div
          className={`w-full xl:w-[1200px] ${
            SHOW_ANNOUCEMENT_BANNER ? "mt-28 md:mt-36" : "mt-20 md:mt-28"
          } `}
        >
          <div className="px-2">
            <div className="flex justify-between items-center px-[15px]  py-[5px]  h-[100px]  w-100  md:max-lg:px-0 lg:py-[29px] ">
              <SearchBar
                placeHolder={"Search"}
                searchQuery={searchQuery}
                searchHandler={searchHandler}
              />
              <button
                onClick={() => setOpenModal(true)}
                className="w-[72px] h-[38px] px-1.5 text-white  justify-center items-center font-avenir900 text-[16px] bg-gradient-to-br from-[#78dd4a] to-[#38c7be]  rounded-full hover:bg-gradient-to-bl    md:hidden"
              >
                Filters
              </button>
              {openModal && (
                <ModalInFunctionalComponent
                  data={data}
                  filterHandler={filterHandler}
                  isFilter={isFilter}
                  openModal={true}
                  setOpenModal={setOpenModal}
                  levelHandler={levelHandler}
                  isToxic={isToxic}
                />
              )}
            </div>
            <FullScreenFilter
              data={data}
              filterHandler={filterHandler}
              isFilter={isFilter}
              levelHandler={levelHandler}
              setHasFilter={setHasFilter}
              hasFilter={hasFilter}
              isToxic={isToxic}
            />

            <div className="flex  max-w-full px-4  md:max-lg:px-0">
              <div className=" w-full grid grid-cols-2 gap-x-4 md:grid-cols-3  lg:grid-cols-4 lg:gap-x-6  ">
                {plants?.length === 0 ? (
                  <div className=" px-3 py-2"> No data found </div>
                ) : (
                  plants?.map((i, index) => {
                    let str = i.node.slug;
                    return (
                      <Link
                        role="button"
                        key={index}
                        to={`/explore-plants/${str}/`}
                        className="mb-4 whitespace-normal hover:scale-95 transition duration-200 cursor-pointer"
                      >
                        <LazyLoadImage
                          src={i?.node?.imageURL}
                          alt={i.node?.slug}
                          effect="opacity"
                          className="rounded-2xl aspect-square w-full md:w-full object-cover"
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = PlantPlaceholder;
                          }}
                        />
                        <p className="font-avenir800 text-body text-xl md:text-2xl">
                          {i.node?.common_name}
                        </p>
                        <p className="font-avenir900 text-gray text-xs md:text-lg">
                          {i?.node?.scientific_name}
                        </p>
                      </Link>
                    );
                  })
                )}
              </div>
            </div>
          </div>
          <div className="flex  flex-row justify-center text-base text-avenir400 px-5 font-bold my-5 sm:hidden">
            <EllipsePagination
              currentPage={pageContext.currentPage}
              totalPages={pageContext.numPages}
              onNumberClick={onNumberClick}
            />
          </div>

          <div className="flex flex-row justify-between text-base text-avenir400 px-5 font-bold my-5  md:text-xl lg:px-48">
            <button
              onClick={() => navigate(previousPageUrl)}
              disabled={pageContext.currentPage === 1 ? true : false}
              className={`flex justify-center items-center bg-[#E7F6FB] rounded-2xl text-[#07BBE3] py-2 px-5 cursor-pointer w-32 ${
                pageContext.currentPage === 1 ? "invisible" : "visible"
              }`}
            >
              <LeftArrow />
              Prev
            </button>
            <div className="hidden sm:block	">
              <EllipsePagination
                currentPage={pageContext.currentPage}
                totalPages={pageContext.numPages}
                onNumberClick={onNumberClick}
              />
            </div>
            <Link
              to={nextPageUrl}
              className={`flex justify-center items-center	bg-[#E7F6FB] rounded-2xl text-[#07BBE3] py-2 px-6 cursor-pointer w-32 ${
                pageContext.currentPage === pageContext.numPages
                  ? "invisible"
                  : "visible"
              }`}
            >
              Next
              <RightArrow />
            </Link>
          </div>
          <BlogFooter />
        </div>
      </div>
      <div className="fixed w-full z-10 " style={{ bottom: "12px" }}>
        <DownloadAppBar className="my-0 mx-auto" />
      </div>
    </Layout>
  );
};
export const query = graphql`
  query ($skip: Int, $limit: Int) {
    localSearchPages {
      index
      store
    }
    allPlants(limit: $limit, skip: $skip) {
      edges {
        node {
          common_name
          imageURL
          imageURL_1
          imageURL_2
          imageURL_3
          imageURL_4
          id
          slug
          plant_types
          family
          scientific_name
        }
      }
    }
    data: allPlants {
      edges {
        node {
          id
          slug
          plant_types
          imageURL
          scientific_name
          common_name
        }
      }
    }
  }
`;
export default Plant;
