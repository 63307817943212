import React from 'react';
import image1 from '../../../images/icons/image1.png';
import right from '../../../images/icons/right.svg';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import moment from 'moment';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const BlogFooter = () => {
  const data = useStaticQuery(graphql`
    {
      allGraphCmsPost(limit: 3) {
        edges {
          node {
            title
            slug
            date
            coverImage {
              url
            }
          }
        }
      }
    }
  `);

  const blogs = data?.allGraphCmsPost?.edges?.map((edge) => {
    return edge?.node;
  });

  return (
    <div className="h-100vh bg-[#F9FCFE] pl-3 py-4 flex flex-col items-center md:py-8">
      <div className="w-full xl:w-[1200px]">
        <div className="flex flex-col flex-nowrap whitespace-nowrap py-5 justify-center mx-auto md:flex-row  md:px-8 font-semibold  md:flex md:max-lg:px-0">
          <div className="flex flex-col  flex-nowrap whitespace-pre-wrap  mb-4 items-start justify-center md:w-1/5 md:mb-0">
            <div className="text-3xl font-avenir800 md:text-4xl">Check our blog</div>
            <p className="text-2xl font-avenir800 text-[#A0ACB7] mt-2 pr-3 md:text-2xl">
              Discover Different Topics
            </p>
          </div>

          <div className="flex flex-row flow-nowarp overflow-scroll md:overflow-hidden ">
            <ul className="flex  flex-nowrap">
              {blogs?.map((post, index) => (
                <li
                  
                  className=" cursor-pointer mr-3"
                  key={index}
                  onClick={() => navigate(`/blogs/${post?.slug?.toLowerCase()}/`)}
                >
                  <LazyLoadImage
                    className="w-64 h-48 rounded-xl md:w-64 md:h-48 object-cover"
                    src={post?.coverImage?.url || image1}
                    alt={post?.coverImage?.fileName || 'blog-feature-img'}
                  />
                  <p className="w-64 underline underline-offset-4 font-avenir800  text-xl sm:w-64 sm:text-xl mt-3 whitespace-pre-wrap">
                    {post?.title}
                  </p>
                  <p className="text-gray mt-3 text-base md:text-md">
                    {moment(post?.date).format('DD MMM YYYY')}
                  </p>
                </li>
              ))}
            </ul>
            <div
              role='button'
              className="ml-3 flex flex-col justify-center item-center md:mr-3"
              onClick={() => navigate('/blogs/')}
            >
              <LazyLoadImage className="h-8 cursor-pointer" src={right} alt="arrow_img" />
              <p className="text-xl mr-5 md:text-3xl font-avenir800 cursor-pointer  md:mr-0">
                See
                <br /> more
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogFooter;
