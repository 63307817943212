import React from "react";
import AccordionLayout from "../../Filter/demo/accordionLayout";

const CARE_DIFFICULT = [
  { label: "Pet Friendly", value: "TRUE" },
  { label: "Low light", value: "Toxic" },
];

const FullScreenFilter = ({
  data,
  filterHandler,
  isFilter,
  levelHandler,
  setHasFilter,
  hasFilter,
  isToxic,
}) => {
  let plants = data?.edges;
  let plantTypes = [];

  plants?.filter((ele) => {
    let isDuplicate = plantTypes?.includes(ele?.node?.plant_types);
    if (!isDuplicate) {
      plantTypes.push(ele?.node?.plant_types);
      return true;
    }
    return false;
  });

  return (
    <div className="flex mb-6 max-w-full px-4  md:max-lg:px-0">
      <div className=" hidden flex  w-full  border-solid md:block  shadow-xl shadow-[0px_0px_6px_rgba(0,0,0,0.1)] h-full rounded-2xl p-3">
        <AccordionLayout
          title="Filter by:"
          state={hasFilter}
          onClick={() => setHasFilter(!hasFilter)}
        >
          {hasFilter && (
            <div className="py-3 px-4 flex flex-wrap">
              <div className="w-9/12">
                <div className="text-xl font-extrabold">Type</div>
                <ul
                  className="p-3 text-sm grid grid-cols-3 gap-4"
                  aria-labelledby="dropdownCheckboxButton"
                >
                  {plantTypes?.map((item, index) => (
                    <div key={index}>
                      <li key={index} class="flex items-center  ">
                        <input
                          id={index}
                          type="checkbox"
                          checked={isFilter.includes(item)}
                          value={item}
                          className="w-5 h-5 text-green bg-gray-100 rounded-xl border-gray-300 focus:ring-0 fous-outline-none cursor-pointer"
                          onClick={filterHandler}
                        />
                        <label
                          for={index}
                          class=" text-base font-medium text-avenir400 cursor-pointer ml-2"
                        >
                          {item}
                        </label>
                      </li>
                    </div>
                  ))}
                </ul>
              </div>
              <div className="w-3/12">
                <div className="text-xl font-extrabold">Care difficulty</div>
                <ul
                  className="p-3 space-y-3 text-sm"
                  aria-labelledby="dropdownCheckboxButton"
                >
                  {CARE_DIFFICULT.map((item, index) => {
                    return (
                      <li className="flex items-center">
                        <input
                          id={`radio-button-for-${index}`}
                          type="checkbox"
                          value={item.value}
                          onClick={levelHandler}
                          checked={isToxic === item.value}
                          className=" w-5 h-5 text-green bg-gray-100 rounded-xl border-gray-300 focus:ring-0 focus-outline-none cursor-pointer"
                        />
                        <label
                          for={`radio-button-for-${index}`}
                          className="flex text-base font-medium text-avenir400 cursor-pointer ml-2"
                        >
                          {item.label}
                        </label>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          )}
        </AccordionLayout>
      </div>
    </div>
  );
};
export default FullScreenFilter;
